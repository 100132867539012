@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

body {
  background-color: #1d2027;
  font-family: "Poppins", sans-serif;
  color: #b8b8b8;
}

.title-container {
  margin-top: 24px;
}

.title-text {
  text-align: center;
  font-size: 32px;
  color: #f0eeee;
}

.subtitle-text {
  text-align: center;
  color: #b8b8b8;
  font-size: 14px;
}

.install-banner {
  background-color: #17191f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.install-text {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 8px 0;
  margin: 0;
}

.install-btn {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #e3e3e3;
  padding: 5px 18px;
  font-size: 16px;
  cursor: pointer;
}

.install-btn:hover {
  background-color: #0270e6;
}

.div-disabled {
  pointer-events: none;
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.footer {
  margin-top: 12px;
  text-align: center;
  font-size: 12px;
  line-height: 2px;
}
