@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
.dashboard.title-text,
.attendance.title-text {
  font-size: 24px;
  font-weight: 500;
  color: #f0eeee;
}

.app-container {
  margin-top: 24px;
}

.widget-container {
  background-color: #272b34;
  color: #f0eeee;
  padding: 14px 14px;
  border-radius: 14px;
  margin: 16px auto;
}

.widget-title-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #fd4d21;
}

.widget-header {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.widget-content {
  padding: 0 16px;
}

.widget-text {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
}

.qr-widget {
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-img {
  width: 100%;
}

.qr-img-caption {
  margin: 8px 0;
}

.preview-qr {
  width: 80%;
}

.attendance .card {
  background-color: #272b34;
}

.attendance .card-header .btn.btn-link {
  color: #f0eeee;
}

.fa-link {
  color: #fd4d21;
  font-size: 16px;
}

.view-more-link {
  font-size: 16px;
}

.qr-full {
  padding: 100px 0;
}

.hidden {
  display: none;
}

.visible {
  display: inline;
}

.install-navbar {
  width: 100%;
}

.submit-btn.verify-btn {
  margin-top: 0;
}

.login {
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.google-signin-container {
  margin-top: 48px;
  padding: 0 96px;
}

.google-signin-btn {
  background-color: #f0eeee;
  width: 100%;
  border: none;
  border-radius: 16px;
  color: #fd4d21;
  padding: 18px 0;
  font-size: 18px;
  cursor: pointer;
}

.google-signin-btn:hover {
  background-color: #cccccc;
}

.logo-img {
  display: block;
  margin: 0 auto 10px;
}

.email-login-text {
  margin-top: 16px;
}

.alert-container {
  /* height: 72px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-container.signup {
  margin-top: 24px;
}

.form-container {
  padding: 0 12px;
}

.submit-btn {
  background-color: #fd4d21;
  width: 100%;
  border: none;
  border-radius: 16px;
  color: #e3e3e3;
  padding: 10px 0;
  font-size: 18px;
  cursor: pointer;
  margin-top: 32px;
}

.submit-btn:hover {
  background-color: #db3910;
}

body {
  background-color: #1d2027;
  font-family: "Poppins", sans-serif;
  color: #b8b8b8;
}

.title-container {
  margin-top: 24px;
}

.title-text {
  text-align: center;
  font-size: 32px;
  color: #f0eeee;
}

.subtitle-text {
  text-align: center;
  color: #b8b8b8;
  font-size: 14px;
}

.install-banner {
  background-color: #17191f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.install-text {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 8px 0;
  margin: 0;
}

.install-btn {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #e3e3e3;
  padding: 5px 18px;
  font-size: 16px;
  cursor: pointer;
}

.install-btn:hover {
  background-color: #0270e6;
}

.div-disabled {
  pointer-events: none;
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.footer {
  margin-top: 12px;
  text-align: center;
  font-size: 12px;
  line-height: 2px;
}

