.login {
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.google-signin-container {
  margin-top: 48px;
  padding: 0 96px;
}

.google-signin-btn {
  background-color: #f0eeee;
  width: 100%;
  border: none;
  border-radius: 16px;
  color: #fd4d21;
  padding: 18px 0;
  font-size: 18px;
  cursor: pointer;
}

.google-signin-btn:hover {
  background-color: #cccccc;
}

.logo-img {
  display: block;
  margin: 0 auto 10px;
}

.email-login-text {
  margin-top: 16px;
}

.alert-container {
  /* height: 72px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-container.signup {
  margin-top: 24px;
}

.form-container {
  padding: 0 12px;
}

.submit-btn {
  background-color: #fd4d21;
  width: 100%;
  border: none;
  border-radius: 16px;
  color: #e3e3e3;
  padding: 10px 0;
  font-size: 18px;
  cursor: pointer;
  margin-top: 32px;
}

.submit-btn:hover {
  background-color: #db3910;
}
