.dashboard.title-text,
.attendance.title-text {
  font-size: 24px;
  font-weight: 500;
  color: #f0eeee;
}

.app-container {
  margin-top: 24px;
}

.widget-container {
  background-color: #272b34;
  color: #f0eeee;
  padding: 14px 14px;
  border-radius: 14px;
  margin: 16px auto;
}

.widget-title-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #fd4d21;
}

.widget-header {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.widget-content {
  padding: 0 16px;
}

.widget-text {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
}

.qr-widget {
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-img {
  width: 100%;
}

.qr-img-caption {
  margin: 8px 0;
}

.preview-qr {
  width: 80%;
}

.attendance .card {
  background-color: #272b34;
}

.attendance .card-header .btn.btn-link {
  color: #f0eeee;
}

.fa-link {
  color: #fd4d21;
  font-size: 16px;
}

.view-more-link {
  font-size: 16px;
}

.qr-full {
  padding: 100px 0;
}

.hidden {
  display: none;
}

.visible {
  display: inline;
}

.install-navbar {
  width: 100%;
}

.submit-btn.verify-btn {
  margin-top: 0;
}
